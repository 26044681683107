import ReactDOM from 'react-dom/client'
// Redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './app/store'
// Components
import App from './App'
// MSAL
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'components/Azure/authConfig'
import SplashScreen from 'components/SplashScreen'

export const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <PersistGate loading={<SplashScreen />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </MsalProvider>
)
