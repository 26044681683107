import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

// Slices
import alertSlice from './slices/alertSlice'
import authSlice from './slices/authSlice'
import invoiceMaintenance from './slices/invoiceMaintenanceSlice'
import listSlice from './slices/listSlice'
import pageSlice from './slices/pageSlice'
import projectPlanningSlice from './slices/projectPlanningSlice'
import projectSlice from './slices/projectSlice'
import snackbarSlice from './slices/snackbarSlice'
import staffSlice from './slices/staffSlice'
import timeSheetSlice from './slices/timeSheetSlice'
import weekSlice from './slices/weekSlice'
// redux-persist
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
    auth: authSlice,
    projectPlanning: projectPlanningSlice,
    pageInfo: pageSlice,
    staff: staffSlice,
    list: listSlice,
    snackbar: snackbarSlice,
    alert: alertSlice,
    weekInfo: weekSlice,
    project: projectSlice,
    timeSheet: timeSheetSlice,
    invoiceMaintenance: invoiceMaintenance,
})

// store user details in localStorage
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    // devTools: process.env.NODE_ENV !== "production",
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)
