import React from 'react'
// Mui
import { Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
// Custom Styles
import { StyledBox } from 'theme/GlobalStyles'
// Icons
import RefreshIcon from '@mui/icons-material/Refresh'
import { ErrorBoundary as ErrorWrapper } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'

const ErrorFallback = ({ title = 'Error', desc = 'Something went wrong! Please Try to refresh!' }) => {
    return (
        <Root className="fullHeight">
            <Stack alignItems={'center'} justifyContent="center" sx={{ height: '100%' }}>
                <Typography color={'primary'} variant="h4" fontWeight={'bold'} mb={2}>
                    ! {title}
                </Typography>
                <Typography color={'primary'} variant="subtitle2" mb={2}>
                    {desc}
                </Typography>
                <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => window.location.reload()}
                    startIcon={<RefreshIcon />}
                >
                    Refresh
                </Button>
            </Stack>
        </Root>
    )
}

const ErrorBoundary = ({ children }) => {
    const location = useLocation()

    return (
        <ErrorWrapper FallbackComponent={ErrorFallback} resetKeys={[location]}>
            {children}
        </ErrorWrapper>
    )
}

export default ErrorBoundary

const Root = styled(StyledBox)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiTypography-root': {
        textTransform: 'uppercase',
    },
}))
