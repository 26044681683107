import styled from '@emotion/styled'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup as MuiRadioGroup } from '@mui/material'
import React from 'react'
import palette from 'theme/palette'

const RadioGroup = ({ label, radioList, value, onChange, name, color, fullWidth, gap, ...rest }) => {
    return (
        <FormControl fullWidth={fullWidth} sx={{ '& .MuiFormControlLabel-label': { width: '100%' } }}>
            {label && (
                <StyledFormLabel
                    id="demo-row-radio-buttons-group-label"
                    style={{ color: color ? palette[color].main : palette.grey[700] }}
                >
                    {label}
                </StyledFormLabel>
            )}

            <MuiRadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={name}
                value={value}
                sx={{ gap }}
                onChange={(e) => onChange(e, e.target.value, e.target.name)}
                {...rest}
            >
                {radioList?.map((item, index) => {
                    return (
                        <FormControlLabel
                            sx={{ fontWeight: 'normal' }}
                            key={index}
                            value={item.value}
                            control={<Radio />}
                            label={item.label}
                            disabled={rest.disabled}
                        />
                    )
                })}
            </MuiRadioGroup>
        </FormControl>
    )
}

export default RadioGroup

const StyledFormLabel = styled(FormLabel)({
    fontSize: '13px !important',
    fontWeight: 500,
})
