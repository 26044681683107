import styled from '@emotion/styled'
import { Backdrop } from '@mui/material'
import AppImage from 'constants/images'
import React from 'react'

const SplashScreen = () => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: '#fff',
            }}
            open={true}
        >
            <Logo src={AppImage.Logo} alt="" />
        </Backdrop>
    )
}

export default SplashScreen

const Logo = styled('img')({
    '@keyframes pulsate1': {
        '0%': {
            opacity: 0.3,
            transform: 'scale(.5)',
        },
        '50%': {
            opacity: 0.7,
            transform: 'scale(.8)',
        },
        '100%': {
            opacity: 1,
            transform: 'scale(.5)',
        },
    },
    animation: 'pulsate1 2s infinite ease',
})
