console.log(process.env.REACT_APP_ENV)

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}api`
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const BASE_PATH = process.env.REACT_APP_BASE_URL
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL

// azure active directory
export const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID

export const AZURE_CLOUD_INSTANCE_ID = process.env.REACT_APP_AZURE_CLOUD_INSTANCE_ID
export const AZURE_TENANT_ID = process.env.REACT_APP_AZURE_TENANT_ID

// Sidebar Config
export const SIDEBAR_MAX_WIDTH = 260
export const SIDEBAR_MIN_WIDTH = 120

export const DATE_FORMAT = 'DD MMM YYYY'

export const DB_DATE_FORMAT = 'YYYY-MM-DD'

export const INVALID_YEAR = '1970'
export const DEFAULT_DATE = '2001-01-01'
export const START_PUBLIC_HOLIDAY_DATE = '2012-01-01'

//Add Project
export const ClassificationOpportunity = 1
export const ClassificationProposal = 2
export const ClassificationProject = 3

//Project Types
export const ProjectTypeExternal = 1
export const ProjectTypeNonFee = 2

export const internalTenderProjectNo = 9101
