import { LogLevel } from '@azure/msal-browser'
import { AZURE_CLIENT_ID, AZURE_CLOUD_INSTANCE_ID, AZURE_TENANT_ID, FRONTEND_URL } from '../../constants/config'

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: AZURE_CLIENT_ID,
        authority: AZURE_CLOUD_INSTANCE_ID + '/' + AZURE_TENANT_ID,
        redirectUri: `${FRONTEND_URL}checking-authentication-status`,
        // postLogoutRedirectUri: `${FRONTEND_URL}login`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
        secureCookies: false,
    },
    system: {
        // windowHashTimeout: 600, // Applies just to popup calls - In milliseconds
        // iframeHashTimeout: 900,	// Applies just to silent calls - In milliseconds
        // loadFrameTimeout: 900,		// Applies to both silent and popup calls - In milliseconds
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        return
                    case LogLevel.Verbose:
                        return
                    case LogLevel.Warning:
                        console.warn(message)
                        return
                    default:
                        return
                }
            },
        },
    },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
}
export const silentRequest = {
    scopes: ['User.Read'],
    loginHint: 'user@artd.com.au',
}
