import { useEffect, useLayoutEffect } from 'react'
// Router
import { useLocation, useNavigate } from 'react-router-dom'
import { urls } from '../../routes'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { authOnLoadAsync, selectAuth } from '../../../../app/slices/authSlice'
// Components
import { setModuleId } from 'app/slices/pageSlice'
import SplashScreen from 'components/SplashScreen'
import Error from '../pages/Error'

const SecureRoute = ({ children, module_id }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useLayoutEffect(() => {
        // save token to redux store
        async function authLoad() {
            try {
                dispatch(authOnLoadAsync())
            } catch (error) {
                console.log(error)
            }
        }

        authLoad()
    }, [dispatch])

    useEffect(() => {
        dispatch(setModuleId(module_id))
        return () => {
            dispatch(setModuleId(null))
        }
    }, [dispatch, module_id])

    // redux store
    const { isLoggedIn, token, user, loading } = useSelector(selectAuth)

    if (loading) {
        return <SplashScreen />
    }
    // if not logged in
    if (!isLoggedIn || token === null) {
        setTimeout(() => {
            navigate(urls.login, { replace: true, state: { from: location } })
        }, 2000)

        return <SplashScreen />
    }

    // to check module access
    const user_modules = (user && user.Modules) || null
    const checkAccess = module_id && user_modules && user_modules.some((item) => item.ID === module_id)

    if (!checkAccess && user) {
        // access denied
        return <Error title="401 - Unauthorized" desc="You aren’t authenticated to access this page." />
    }

    return children
}

export default SecureRoute
