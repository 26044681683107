import api from 'utils/api'

export const panelMaintenance = (data) => {
    return api.post('/panelMaintenance', data)
}

export const getActivePanels = (data) => {
    return api.post('/panelMaintenance/getActivePanels', data)
}

export const Store = (data) => {
    return api.post(`/panelMaintenance/store`, data, {
        'content-type': 'multipart/form-data',
    })
}

export const Delete = (data) => {
    return api.post(`/panelMaintenance/delete`, data)
}

export const getRates = (data) => {
    return api.post(`/v2/government-panel-rates/get-rate-details`, data)
}

export const saveRates = (data) => {
    return api.post(`/v2/government-panel-rates/set-rate`, data)
}

export const panelMaintenanceApi = {
    panelMaintenance,
    getActivePanels,
    Store,
    Delete,
    getRates,
    saveRates,
}
