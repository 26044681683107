import {
    Box,
    Button,
    FormGroup,
    IconButton,
    InputLabel,
    Stack,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { blue, lightGreen, red, teal } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import RadioGroup from 'components/Form/RadioGroup'
import DataTable from '../components/DataTable'
import palette from './palette'

/*
	* In this page we provided additional custom styling.
	* To override specific styles of default Mui component and To create own styled components.

*/

export const StyledBox = styled(Box)(({ theme, border }) => ({
    minHeight: '60px',
    backgroundColor: palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 10,
    // boxShadow: "0px 0px 4px rgba(166, 166, 166, 0.25)",
    gap: theme.spacing(3),
    border: border && '1px solid rgba(0, 0, 0, 0.1)',
    // '& .MuiBox-root': {
    //     border: 'none',
    // },
    '&.fullHeight': {
        minHeight: '85vh',
    },
    '&.readOnly': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}))

export const StyledInput = styled('input')(({ theme }) => ({
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1.4),
    border: `1px solid #d4d9decc`,
    color: theme.palette.grey[700],
    fontFamily: 'Poppins,sans-serif',
    width: '100%',
    outline: 'none',
    borderRadius: 3,
    '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
        color: theme.palette.grey[500],
        border: `1px solid #d4d9decc`,
        backgroundColor: theme.palette.grey[200],
    },
    '&[data-error="true"]': {
        border: `1px solid #FF4842`,
    },
}))

export const CustomBox = styled(StyledBox)(({ theme }) => ({
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'none',
    height: '100%',
    borderRadius: 5,
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
    },
    '&.readOnly': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}))

export const StyledBtn = styled(Button)(({ theme, radius, flat }) => ({
    padding: 0,
    paddingBlock: theme.spacing(0.8),
    paddingInline: theme.spacing(2),
    minWidth: 'unset',
    [theme.breakpoints.down('lg')]: {
        // paddingBlock: theme.spacing(0.6),
        paddingInline: theme.spacing(1.8),
    },
    '&.MuiButton-contained': {
        paddingBlock: theme.spacing(0.92),
        paddingInline: theme.spacing(2.12),
    },
    '& .MuiButton-startIcon': {
        marginLeft: 0,
        marginRight: 8,
    },
    whiteSpace: 'nowrap',
    ...(flat === 'top' && {
        '&.MuiButtonBase-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
    }),
    ...(flat === 'bottom' && {
        '&.MuiButtonBase-root': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    }),
    ...(flat === 'both' && {
        '&.MuiButtonBase-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    }),
}))

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '18px !important',
    fontWeight: '500',
    textTransform: 'capitalize',
}))

export const FormBtn = styled(Button)(({ theme, radius }) => ({
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.9),
    paddingInline: theme.spacing(2),
    fontWeight: '600',
    border: `1px solid ${palette.primary.main}`,
}))

export const StyledCheckBoxWrapper = styled(FormGroup)(({ theme }) => ({
    '& .MuiFormControlLabel-root ': {
        marginLeft: 0,
    },
}))
export const ButtonStack = styled(Stack)(({ theme, btnwidth }) => ({
    gap: theme.spacing(1),
    '& .MuiButton-root': {
        minWidth: btnwidth ? btnwidth : 'auto',
    },
    '& .MuiButton-outlined': {
        backgroundColor: palette.common.white,
    },
}))

export const StyledPageTitle = styled((props) => <Typography variant="h5" {...props} />)(({ theme }) => ({
    color: palette.primary.main,
    fontWeight: '600',
    fontSize: 20,
}))
export const CustomBoxTitle = styled((props) => <Typography variant="subtitle4" component={'p'} {...props} />)(
    ({ theme }) => ({
        color: palette.primary.main,
    })
)

export const StyledTextField = styled('input')(({ theme }) => ({
    padding: theme.spacing(1),
    border: `1px solid #d4d9decc`,
    color: theme.palette.grey[700],
    width: '100%',
    outline: 'none',
    borderRadius: 3,
    '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
        color: theme.palette.grey[500],
        border: `1px solid #d4d9decc`,
    },
}))

export const StyledDiv = styled('div')(({ theme, align }) => ({
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(0.7),
    border: `1px solid #d4d9decc`,
    color: theme.palette.grey[700],
    fontFamily: 'Poppins,sans-serif',
    width: '100%',
    outline: 'none',
    borderRadius: 3,
    minHeight: 30,
    textAlign: align ? align : 'start',
    '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
        color: theme.palette.grey[500],
        border: `1px solid #d4d9decc`,
        backgroundColor: theme.palette.grey[200],
    },
    '&[data-error="true"]': {
        border: `1px solid #FF4842`,
    },
    '&.bg-lightblue': {
        background: theme.palette.secondary.light,
    },
    '&.bg-white': {
        background: theme.palette.common.white,
    },
    '&.bg-green': {
        background: lightGreen[200],
    },
    '&.bg-red': {
        background: red[100],
    },
    '&.bg-blue': {
        background: blue[200],
    },
    '&.bg-blue-light': {
        background: '#D6E9FE',
    },
}))

export const StyledIconInputBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    '& input': {
        paddingBlock: theme.spacing(0.5),
        paddingInline: theme.spacing(1.4),
        border: `1px solid #d4d9decc`,
        color: theme.palette.grey[700],
        fontFamily: 'Poppins,sans-serif',
        width: '100%',
        outline: 'none',
        borderRadius: 3,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:disabled': {
            color: theme.palette.grey[500],
            border: `1px solid #d4d9decc`,
            backgroundColor: theme.palette.grey[200],
        },
        '&[data-error="true"]': {
            border: `1px solid #FF4842`,
        },
    },
    '&.errorInput input': {
        paddingRight: '40px',
    },
    '& svg': {
        position: 'absolute',
        right: 0,
        top: '4px',
        padding: '3px 2px',
        color: theme.palette.grey[700],
        transition: '0.3s',
        '&.error': {
            display: 'block',
            color: '#FF4842',
        },
        '&.noError': {
            display: 'none',
        },
    },
    '&.dropDown input': {
        paddingRight: '35px',
    },
    '&.dropDown svg': {
        top: '0px',
        padding: '0px',
        backgroundColor: '#e7ebf0',
        height: '30px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        fontSize: '25px',
    },
}))

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    border: `1px solid ${theme.palette.border}`,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    boxShadow: 'none',

    '& .MuiTableCell-head': {
        padding: theme.spacing(1.5),
        // fontSize: 16,
        textTransform: 'uppercase',
        backgroundColor: palette.secondary.main,
        color: palette.common.white,
    },

    '& .MuiTableCell-body': {
        paddingBlock: theme.spacing(0.8),
        paddingInline: theme.spacing(0.8),
        '&.MuiTableCell-paddingCheckbox': {
            // paddingBlock: theme.spacing(0.4),
            width: 'unset',
        },
        '& .bg-white .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
        },
        '& .MuiTableRow-root.disabled': {
            backgroundColor: palette.action.disabledBackground,
        },
    },

    '& .bgLight ': {
        '& .MuiTableRow-root': {
            '& .MuiTableCell-head': {
                backgroundColor: theme.palette.highlight,
                color: theme.palette.primary.main,
                borderBottom: `1px solid ${theme.palette.primary.borderLight}`,
                padding: theme.spacing(0.7),
                borderRadius: 'none',
            },
        },
    },

    '& .inputField': {
        paddingBlock: theme.spacing(0.5),
        paddingInline: theme.spacing(1.4),
        border: `1px solid #d4d9decc`,
        color: theme.palette.grey[700],
        fontFamily: 'Poppins,sans-serif',
        width: '100%',
        outline: 'none',
        borderRadius: 3,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:disabled': {
            color: theme.palette.grey[500],
            border: `1px solid #d4d9decc`,
            backgroundColor: theme.palette.grey[200],
        },
        '&[data-error="true"]': {
            border: `1px solid #FF4842`,
        },
    },
    '& .selectField': {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            '& .MuiAutocomplete-input ': {
                padding: '6px !important',
                paddingRight: 0,
                fontSize: 13,
            },
            '& .MuiButtonBase-root': {
                marginRight: 0,
                padding: 0,
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
        },
    },

    '& .stripped': {
        '& .MuiTableRow-root': {
            '&:nth-of-type(even)': {
                backgroundColor: palette.action.hover,
            },
        },
    },

    '& .innerTableCell': {
        paddingBlock: '7px',
        paddingInline: '4px',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
}))

export const StyledReportTableContainer = styled(TableContainer)(({ theme }) => ({
    borderRadius: 10,
    marginBottom: 15,

    '&.sticky': {
        maxHeight: '600px',
        '&.projectQA': {
            borderRadius: 0,
            '& .MuiDataGrid-columnHeaders': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                '& .MuiDataGrid-columnHeadersInner': {
                    backgroundColor: '#daedf4',
                    border: '0.5px solid #b2e0f0',
                    color: '#105776'
                }
            },
            '& .MuiDataGrid-virtualScrollerContent': {
                border: '1px solid #49A7C533'
            }
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            '& .MuiTableHead-root': {
                position: 'sticky',
                top: 0,
                zIndex: 3,
                '& .MuiTableCell-root': {
                    backgroundColor: theme.palette.tableHeader,
                    border: `0.5px solid ${theme.palette.tableBorder}`,
                    color: theme.palette.primary.main,
                },
            },
            '& .MuiTableBody-root': {
                '& .MuiTableCell-root': {
                    // background: theme.palette.common.white,
                    border: `1px solid ${theme.palette.secondary.borderLight}`,
                },
            },

            '& .MuiTableCell-root': {
                '&.left-0': {
                    position: 'sticky',
                    left: 0,
                },
                '&.border-sticky': {
                    borderRight: '1px solid #ccc',
                },

                '&.teamHeaderCol': {
                    position: 'sticky',
                    left: 0,
                    minWidth: 200,
                    maxWidth: 200,
                },

                '&.staffCol': {
                    position: 'sticky',
                    left: 0,
                    minWidth: 210,
                    maxWidth: 210,
                },

                '&.teamCol': {
                    position: 'sticky',
                    left: 0,
                    minWidth: 130,
                    maxWidth: 130,
                },
                '&.teamColFooter': {
                    position: 'sticky',
                    left: 0,
                    minWidth: 230,
                    maxWidth: 230,
                },
                '&.projectCol': {
                    position: 'sticky',
                    left: 130,
                    minWidth: 100,
                    maxWidth: 100,
                },
                '&.projectTitleCol': {
                    position: 'sticky',
                    left: 230,
                    minWidth: 250,
                    maxWidth: 250,
                },
                '&.staffCodeCol': {
                    position: 'sticky',
                    left: 130,
                    minWidth: 70,
                    maxWidth: 70,
                },
            },
        },
    },

    '& .MuiTableHead-root': {
        backgroundColor: theme.palette.secondary.tableHeader,
        // '& .MuiTableRow-root': {
        //     height: 55,
        // },

        '& .MuiTableCell-root': {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            border: `1px solid ${theme.palette.secondary.borderLight}`,
            // height: 55,
        },
    },
    '& .MuiTableBody-root': {
        border: `1px solid ${theme.palette.secondary.borderLight}`,
        '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
                background: theme.palette.common.white,
            },
        },
    },
    '& .MuiTableRow-root.bg-total': {
        '& .MuiTableCell-root': {
            backgroundColor: '#dbedf3',
        },
    },
    '& .MuiTableRow-root.bg-day-total': {
        '& .MuiTableCell-root': {
            backgroundColor: lightGreen[200],
        },
    },
    '& .MuiTableRow-root.bg-income-total': {
        '&.hide': {
            display: 'none',
        },
        '& .MuiTableCell-root': {
            backgroundColor: teal[100],
        },
    },
    '& .MuiTableCell-root': {
        padding: '6px 10px',
        border: `1px solid ${theme.palette.secondary.borderLight}`,

        '&.border-sticky': {
            borderRight: '1px solid #ccc',
        },

        '&.border-bottom-none': { borderBottom: 'none' },
        '&.border-left-none': { borderLeft: 'none' },
        '&.border-right-none': { borderRight: 'none' },
        '&.border-top-none': { borderTop: 'none' },
        '&.border-block-none': { borderBlock: 'none' },
        '&.border-inline-none': { borderInline: 'none' },
        '&.pr-0': {
            paddingRight: 0,
            '&>*': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            },
        },

        '&.bg-lightblue': {
            background: theme.palette.secondary.light,
        },

        '&.inlinePad': {
            paddingLeft: 30,
        },
        '&.stickyHeader': {
            position: 'sticky',
            top: '66px',
            zIndex: 1,
        },
    },
}))

export const CloseBtn = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: 10,
    right: 10,
}))

export const StyledTableRow = styled(TableRow)(({ theme, disabled }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    '& .MuiSelect-select': {
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            top: 0,
            width: 35,
            height: '100%',
            backgroundColor: palette.border,
            borderLeft: `1px solid #bfbebe`,
        },
    },
    '& .MuiTableCell-root': {
        padding: '6px !important',
        // backgroundColor: theme.palette.common.white,
    },
    '& .noSpace': {
        padding: '0px !important',
    },
    '&.borderTop': {
        '& .MuiTableCell-root': {
            borderTop: `2px solid ${theme.palette.grey[300]}`,
        },
    },
    '& .tableInput': {
        minHeight: 34,
    },

    '& .MuiInputBase-root': {
        minHeight: 'unset',
        ...(!disabled && {
            backgroundColor: '#fff',
        }),

        '& .MuiInputBase-input': {
            padding: '7px',
        },
    },

    ...(disabled && {
        backgroundColor: palette.grey[200],
    }),

    '& .inputField': {
        paddingBlock: theme.spacing(0.5),
        paddingInline: theme.spacing(0.5),
        border: `1px solid #d4d9decc`,
        color: theme.palette.grey[700],
        fontFamily: 'Poppins,sans-serif',
        width: '100%',
        outline: 'none',
        borderRadius: 3,
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&:disabled': {
            color: theme.palette.grey[500],
            border: `1px solid #d4d9decc`,
            backgroundColor: palette.grey[200],
        },
    },
    '& .selectField': {
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            '& .MuiAutocomplete-input ': {
                padding: '6px !important',
                paddingRight: 0,
                fontSize: 13,
            },
            '& .MuiButtonBase-root': {
                marginRight: 0,
                padding: 0,
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
        },
    },
}))

export const CustomToolBar = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
}))

export const StyledDataGrid = styled(DataTable)(({ theme, stripped, primaryheader = true, rounded = true }) => ({
    '& .MuiDataGrid-main': {
        border: `1px solid ${palette.grey[200]}`,

        borderRadius: rounded ? 10 : 0,
    },
    ...(primaryheader && {
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            textTransform: 'uppercase',
            borderTopLeftRadius: rounded ? 10 : 0,
            borderTopRightRadius: rounded ? 10 : 0,
        },
    }),

    '& .MuiDataGrid-cellContent': {
        color: theme.palette.grey[900],
    },
    '& .MuiDataGrid-row': {
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&.Mui-disabled:hover': {
            backgroundColor: palette.grey[200],
        },
        ...(!stripped && { backgroundColor: palette.common.white }),

        ...(stripped && {
            '&:nth-of-type(odd)': {
                backgroundColor: `${palette.action.hover}`,
            },
            '&:hover': {
                backgroundColor: palette.action.hover,
            },
        }),
        '&:last-of-type': {
            borderBottomLeftRadius: rounded ? 10 : 0,
            borderBottomRightRadius: rounded ? 10 : 0,
        },
    },
    '& .MuiDataGrid-cell': {
        '&:focus': {
            outline: 'unset',
        },
        '&:focus-within': {
            outline: 'unset',
        },
        '& span': {
            color: theme.palette.grey[900],
        },
    },
}))

export const StyledIcon = styled(IconButton)(({ theme }) => ({
    objectFit: 'contain',
    textAlign: 'center',
}))

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    '& .MuiFormControlLabel-root': {
        marginRight: 20,
    },
    '& .MuiFormControlLabel-label': {
        fontWeight: 500,
    },
}))
