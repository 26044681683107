import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { commonApi } from '../../modules/admin/api/commonApi'

export const weekInfoAsync = createAsyncThunk('weekInfoAsync', async (data, thunkAPI) => {
    try {
        const res = await commonApi.currentWeekValues()

        return {
            rows: res.data.rows,
            currentWeekCode: res.data.currentWeekCode,
            numberOfWeeksToDisplay: res.data.numberOfWeeksToDisplay,
            maxWeekToDisplay: res.data.maxWeekToDisplay,
            settings: res.data.settings,
        }
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

const weekSlice = createSlice({
    name: 'weekInfo',
    initialState: {
        rows: [],
        currentWeekCode: null,
        numberOfWeeksToDisplay: null,
        maxWeekToDisplay: null,
        currentMonthCode: null,
        settings: {
            GST_Value: 10,
        },
    },
    extraReducers: {
        [weekInfoAsync.fulfilled]: (state, action) => {
            state.rows = action.payload.rows
            state.currentWeekCode = action.payload.currentWeekCode
            state.numberOfWeeksToDisplay = action.payload.numberOfWeeksToDisplay
            state.maxWeekToDisplay = action.payload.maxWeekToDisplay
            state.currentMonthCode = action.payload.rows[0]?.MonthSort
            state.settings = action.payload.settings
        },
    },
})

export default weekSlice.reducer

export const weekInfo = (state) => state.weekInfo
export const getWeekSettings = (state) => state.weekInfo.settings
