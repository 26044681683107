import axios from 'axios'
import { logoutOnError } from '../app/slices/authSlice'
import { store } from '../app/store'
import { BASE_URL } from '../constants/config'
// import Cookies from 'js-cookie' // package removed

const UNAUTHORIZED = 401
const FORBIDDEN = 403
const CSRF_TOKEN_MISMATCH = 419

// Set config defaults when creating the instance
const instance = axios.create({
    baseURL: BASE_URL,
    //withCredentials: true,
})

// Alter defaults after instance has been created
// instance.defaults.headers.common['Authorization'] = 'Bearer ' + '';
// Request interceptor. Runs before your request reaches the server
// const onRequest = (config) => {
//     // If http method is `post | put | delete` and XSRF-TOKEN cookie is
//     // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
//     // proceed with the initial response
//     if (
//         (config.method === 'post' || config.method === 'put' || config.method === 'delete') &&
//         !Cookies.get('XSRF-TOKEN')
//     ) {
//         return setCSRFToken().then((response) => config)
//     }
//     return config
// }

// const setCSRFToken = () => {
//     return instance.get('csrf-cookie') // resolves to '/api/csrf-cookie'.
// }
// attach your interceptor
//instance.interceptors.request.use(onRequest, null)

instance.interceptors.request.use(
    (config) => {
        const { token } = store.getState().auth
        const { module_id, socket } = store.getState().pageInfo

        if (token && token !== null) {
            config.headers['Authorization'] = `Bearer ${token}`
            config.headers['module_id'] = module_id
            config.headers['socketInfo'] = JSON.stringify(socket)
            config.headers['Access-Control-Expose-Headers'] = 'Content-Disposition'
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.status) {
            const resStatus = error.response.status
            if (resStatus === UNAUTHORIZED || resStatus === FORBIDDEN || resStatus === CSRF_TOKEN_MISMATCH) {
                store.dispatch(logoutOnError())
                window.location.reload()
            }
        }
        return Promise.reject(error)
    }
)

export default instance
