import React from 'react'
// Mui
import { Stack, Typography } from '@mui/material'
// Custom Styles
import palette from '../../../../theme/palette'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation('footer')

    return (
        <StyledFooter>
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} my={2}>
                <Typography>
                    {t('text_copyrights')} &copy; {new Date().getFullYear()}{' '}
                    <Typography component={'span'} color={palette.copyright}>
                        {t('text_artd')}
                    </Typography>{' '}
                    {t('text_all_rights_reserved')}
                </Typography>
                <Typography>
                    {t('text_designed_and_developed_by')}{' '}
                    <Typography component={'span'} color={palette.copyright}>
                        {t('text_intergy')}
                    </Typography>
                </Typography>
            </Stack>
        </StyledFooter>
    )
}

export default Footer

const StyledFooter = styled('footer')(() => ({
    width: '100%',
    position: 'absolute',
    bottom: 10,
    paddingInline: 20,
}))
