// Router
import { Route, Routes } from 'react-router-dom'
import adminRoutes from './modules/admin/routes'
// Components
import { ReduxPageLoader } from 'components/PageLoader'
import Snackbar from './components/Snackbar'
import GuestRoute from './modules/admin/auth/helpers/GuestRoute'
import SecureRoute from './modules/admin/auth/helpers/SecureRoute'
import Error from './modules/admin/auth/pages/Error'
import AdminLayout from './modules/admin/layout'

const AppRoutes = () => {
    return (
        <>
            <Routes>
                {/* --------------- Admin module routes------------ */}
                {adminRoutes.map((admin) => {
                    return (
                        <Route
                            key={admin.path}
                            path={admin.path}
                            element={
                                admin.auth ? (
                                    <SecureRoute module_id={admin.module_id}>
                                        <AdminLayout>{admin.element}</AdminLayout>
                                    </SecureRoute>
                                ) : (
                                    <GuestRoute>{admin.element}</GuestRoute>
                                )
                            }
                        />
                    )
                })}
                {/* --------------- Admin module routes------------ */}

                {/* 404 route */}
                <Route path="*" element={<Error />} />
            </Routes>
            <Snackbar />
            <ReduxPageLoader />
        </>
    )
}

export default AppRoutes
