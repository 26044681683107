import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { clientApi } from 'modules/admin/api/clientApi'
import { commonApi } from 'modules/admin/api/commonApi'
import { panelMaintenanceApi } from 'modules/admin/api/panelMaintenanceApi'

import { isEqual } from 'utils/helperFunctions'

const initialState = {
    isLoading: false,
    weekList: [],
    orgTypeList: [],
    govTypeList: [],
    countryList: [],
    stateList: [],
    panelList: [],
    clientList: [],
    projectMastersList: {},
}

export const projectMastersListAsync = createAsyncThunk('projectMastersListAsync', async (data, thunkAPI) => {
    try {
        let response = await commonApi.getProjectMasters(data)
        return response.data.data
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const weekListAsync = createAsyncThunk('weekListAsync', async (data, thunkAPI) => {
    try {
        let response = await commonApi.weekList()
        return response.data.rows
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const panelListAsync = createAsyncThunk('panelListAsync', async (data, thunkAPI) => {
    try {
        let response = await panelMaintenanceApi.getActivePanels()
        return response.data.rows
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const clientListAsync = createAsyncThunk('clientListAsync', async (data, thunkAPI) => {
    try {
        let response = await clientApi.getActiveClients(data)
        return response.data.rows
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setOrgTypeList: (state, action) => {
            state.orgTypeList = action.payload
        },
        setGovTypeList: (state, action) => {
            state.govTypeList = action.payload
        },
        setCountryList: (state, action) => {
            state.countryList = action.payload
        },
        setStateList: (state, action) => {
            state.stateList = action.payload
        },
    },
    extraReducers: {
        // -----------

        [weekListAsync.fulfilled]: (state, action) => {
            state.weekList = !isEqual(action.payload, state.weekList) ? action.payload : state.weekList
        },
        [panelListAsync.fulfilled]: (state, action) => {
            state.panelList = action.payload
        },
        [clientListAsync.fulfilled]: (state, action) => {
            state.clientList = action.payload
        },
        [projectMastersListAsync.fulfilled]: (state, action) => {
            state.projectMastersList = { ...state.projectMastersList, ...action.payload }
        },
    },
})

export const { setLoading } = listSlice.actions
export const { setOrgTypeList } = listSlice.actions
export const { setGovTypeList } = listSlice.actions
export const { setCountryList } = listSlice.actions
export const { setStateList } = listSlice.actions

export const selectList = (state) => state.list
export const projectMastersList = (state) => state.list.projectMastersList

export default listSlice.reducer
