import { ReportProblem } from '@mui/icons-material'
import {
    alpha,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
    Zoom,
} from '@mui/material'
import { hideAlert, resetAlertState } from 'app/slices/alertSlice'
import { forwardRef, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import palette from 'theme/palette'

const btnConfig = {
    confirm: {
        title: 'Yes',
        variant: 'contained',
        color: 'primary',
        visible: true,
        handler: null,
    },
    cancel: {
        title: 'No',
        variant: 'outlined',
        color: 'dark',
        visible: true,
        handler: null,
    },
    close: {
        title: 'Close',
        variant: 'outlined',
        color: 'dark',
        visible: true,
        handler: null,
    },
    delete: {
        title: 'DElete',
        variant: 'contained',
        color: 'error',
        visible: true,
        handler: null,
    },
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
})

const AlertBox = ({ state }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const handleClose = () => {
        dispatch(hideAlert())
        setTimeout(() => {
            dispatch(resetAlertState())
        }, 500)
    }

    let btnRef = useRef()

    function loadBtn() {
        if (!state.actions) {
            return null
        }

        return Object.keys(state.actions).map((item, i) => {
            const handleClick = () => {
                if (state.actions[item].externalLink) {
                    window.open(location.pathname.split('/')[0] + String(state.actions[item].externalLink), '_blank')
                } else {
                    state.actions[item].handler && state.actions[item].handler()
                }
                btnRef.current.setAttribute('disabled', 'disabled')
                handleClose()
            }

            return (
                <Button
                    key={i}
                    variant={state.actions[item].variant || btnConfig[item].variant || 'contained'}
                    color={state.actions[item].color || btnConfig[item].color}
                    onClick={handleClick}
                    sx={{ minWidth: 100 }}
                    ref={i === 0 ? btnRef : null}
                >
                    {state.actions[item].title || btnConfig[item].title || 'close'}
                </Button>
            )
        })
    }

    useEffect(() => {
        return () => {
            dispatch(resetAlertState())
        }
    }, [dispatch, location])

    return (
        <Dialog
            open={state.isAlertOpen}
            TransitionComponent={Transition}
            keepMounted={false}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xs"
            fullWidth
            sx={{
                // left: 260,
                zIndex: state.isSocketAlert && 1190,
                '& .MuiPaper-root': { borderRadius: '10px' },
                '& .MuiBackdrop-root ': {
                    background: '#0000004d',
                    // left: 260,
                },
            }}
        >
            <DialogTitle
                textAlign={'center'}
                //  color="white"
                sx={{ backgroundColor: alpha(palette[state.alertType]?.main, 0.1), backdropFilter: 'blur(5px)' }}
            >
                <Stack direction={'row'} alignItems="center" justifyContent={'flex-start'} spacing={2}>
                    <ReportProblem sx={{ color: palette[state.alertType]?.main }} />{' '}
                    <Typography variant={'subtitle2'} fontWeight="500" fontSize={18}>
                        {' '}
                        {state.title}
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {/* <Typography textAlign={'center'} variant="body3" component={'p'} sx={{ maxWidth: 400, py: 1 }}> */}
                {state.msg}
                {/* </Typography> */}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>{loadBtn()}</DialogActions>
        </Dialog>
    )
}

export default AlertBox
