import { createSlice } from '@reduxjs/toolkit'
import { weekInfoAsync } from './weekSlice'

const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        selectedStaffId: null,
        filterType: 'active',
        startWeek: null,
        selectedParam: null,
        currentWeek: null,
        updatedOn: {
            staffList: new Date().toISOString(),
            staffParams: new Date().toISOString(),
            toAddState: new Date().toISOString(),
        },
        masterList: {
            bandList: [],
            roleList: [],
            staffTypeList: [],
            userTypeList: [],
            stateList: [],
            teamList: [],
            weekList: [],
        },
        flags: {
            isStaffLeft: false,
            staffHasParams: false,
            isFormEdited: false,
        },
    },
    reducers: {
        // Staff list reducers
        selectStaff: (state, action) => {
            state.selectedStaffId = action.payload
            state.startWeek = null
        },
        setMasterList: (state, action) => {
            state.masterList = action.payload
        },
        setStaffFilter: (state, action) => {
            state.filterType = action.payload
        },

        // Staff Param reducers
        selectStaffParam: (state, action) => {
            state.selectedParam = action.payload
        },

        setStartWeek: (state, action) => {
            state.startWeek = action.payload
        },

        resetFlags: (state) => {
            state.selectedStaffId = null
            state.startWeek = null
            state.flags = { isStaffLeft: false, staffHasParams: false, isFormEdited: false }
            state.selectedParam = null
        },

        // Common Reducers
        updatedStaff: (state, action) => {
            if (action.payload) {
                state.updatedOn[action.payload] = new Date().toISOString()
            } else {
                state.updatedOn.staffList = new Date().toISOString()
                state.updatedOn.staffParams = new Date().toISOString()
                state.updatedOn.toAddState = new Date().toISOString()
            }
        },

        updateFlag: (state, action) => {
            const { flag, value } = action.payload
            state.flags[flag] = value
        },
    },
    extraReducers: {
        [weekInfoAsync.fulfilled]: (state, action) => {
            state.startWeek = action.payload.currentWeekCode
            state.currentWeek = action.payload.currentWeekCode
        },
    },
})

export const {
    selectStaff,
    setStaffFilter,
    selectStaffParam,
    updatedStaff,
    setStartWeek,
    resetFlags,
    setMasterList,
    updateFlag,
} = staffSlice.actions

export default staffSlice.reducer

export const selectedStaffId = (state) => state.staff.selectedStaffId
export const StaffFilterType = (state) => state.staff.filterType
export const staffParam = (state) => state.staff.selectedParam
export const isStaffUpdate = (state) => state.staff.updatedOn
export const staffInfo = (state) => state.staff
export const getMasterList = (state) => state.staff.masterList
export const getStartWeek = (state) => state.staff.startWeek
export const getCurrentWeek = (state) => state.staff.currentWeek
export const getFlags = (state) => state.staff.flags
export const isFormEditedFlag = (state) => state.staff.flags.isFormEdited
export const staffHasParamsFlag = (state) => state.staff.flags.staffHasParams
export const isStaffLeftFlag = (state) => state.staff.flags.isStaffLeft
