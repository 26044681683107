import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import Cookies from 'js-cookie' // Package removed
import { authApi } from '../../modules/admin/api/authApi'
import { weekInfoAsync } from './weekSlice'

const initialState = {
    loading: false,
    // isLoggedIn: Cookies.get("XSRF-TOKEN") ? true : false,
    // token: Cookies.get("XSRF-TOKEN") || null,
    isLoggedIn: false,
    token: null,
    user: null,
}

// Api to fetch user details on refresh
export const authOnLoadAsync = createAsyncThunk('authOnLoad', async (data, thunkAPI) => {
    const { isLoggedIn, token } = thunkAPI.getState().auth

    if (isLoggedIn || (token && token !== null)) {
        try {
            const response = await authApi.user()
            if (response.data.token) {
                thunkAPI.dispatch(weekInfoAsync())
                return {
                    isLoggedIn: true,
                    token: response.data.token,
                    user: response.data.user,
                }
            }

            return {
                isLoggedIn: false,
                token: null,
                user: null,
                loading: false,
            }
        } catch (error) {
            return {
                isLoggedIn: false,
                token: null,
                user: null,
                loading: false,
            }
        }
    }

    return thunkAPI.rejectWithValue()
})

export const authLogoutAsync = createAsyncThunk('authLogoutAsync', async (data, thunkAPI) => {
    try {
        const { isLoggedIn } = thunkAPI.getState().auth
        if (isLoggedIn) {
            await authApi.logout()
        }
        return {
            isLoggedIn: false,
            token: null,
            user: null,
            loading: false,
        }
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLogin: (state, action) => {
            state.isLoggedIn = true
            state.token = action.payload.token
            state.user = action.payload.user
        },
        authUserUpdate: (state, action) => {
            state.user = action.payload
        },
        logoutOnError: (state, action) => {
            state.isLoggedIn = false
            state.token = null
            state.user = null
            state.loading = false
        },
    },
    extraReducers: {
        [authOnLoadAsync.pending]: (state) => {
            state.loading = true
        },
        [authOnLoadAsync.fulfilled]: (state, action) => {
            state.loading = false
            state.isLoggedIn = action.payload.isLoggedIn
            state.token = action.payload.token
            state.user = action.payload.user
        },
        [authOnLoadAsync.rejected]: (state) => {
            state.loading = false
        },

        [authLogoutAsync.pending]: (state) => {
            state.loading = true
        },
        [authLogoutAsync.fulfilled]: (state, action) => {
            // Cookies.remove('XSRF-TOKEN')
            state.loading = false
            state.isLoggedIn = action.payload.isLoggedIn
            state.token = action.payload.token
            state.user = action.payload.user
        },
        [authLogoutAsync.rejected]: (state) => {
            // Cookies.remove('XSRF-TOKEN')
            state.loading = false
            state.isLoggedIn = false
            state.token = null
            state.user = null
        },
    },
})

export const { authLogin, authUserUpdate, logoutOnError } = authSlice.actions

export const selectAuth = (state) => state.auth
export const currentUser = (state) => state.auth.user

export default authSlice.reducer
