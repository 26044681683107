// Mui
import styled from '@emotion/styled'
import { FormControl, FormHelperText, OutlinedInput } from '@mui/material'
// Custom Styles
import { StyledLabel } from 'theme/GlobalStyles'
import { capFirstLetter } from 'utils/helperFunctions'

/**
 * InputField
 * @param {string} name Input Field name
 * @param {string} label Input Field Label
 * @param {string} width Width of the Input Field
 * @param {boolean} disabled Flag to disable Input field
 * @param {boolean} withHelperText Flag to show or hide HelperText
 * @param {boolean} disableErrorColor To Disable error highlight on label and input border
 */

const Input = (props) => {
    const {
        label,
        withHelperText = false,
        error,
        width,
        height,
        disableErrorColor = false,
        helperText,
        ...rest
    } = props

    return (
        <FormControl
            variant="standard"
            sx={{ width: width ? width : '100%', height: rest?.multiline ? '100%' : 'unset' }}
            fullWidth
            style={props.style}
        >
            {label && (
                <StyledLabel error={!disableErrorColor && error && !!error} shrink htmlFor={label}>
                    {label}
                </StyledLabel>
            )}

            <CustomInput
                sx={{ height: height ? height : '100%' }}
                fullWidth
                id={label}
                error={!disableErrorColor && error && !!error}
                {...rest}
            />
            {withHelperText && <FormHelperText error>{!!error ? capFirstLetter(helperText) : ' '}</FormHelperText>}
        </FormControl>
    )
}

export default Input

const CustomInput = styled(OutlinedInput)(({ theme }) => ({
    '&.MuiInputBase-multiline': {
        padding: 0,
        alignItems: 'flex-start',
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px 14px',
    },
    '&.MuiInputBase-sizeSmall': {
        '& .MuiOutlinedInput-input': {
            padding: '5px 10px',
        },
    },
    'label + &': {
        marginTop: theme.spacing(2.8),
    },
    '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000 !important',
    },
    '& .CursorInput .MuiInputBase-input': {
        '& .MuiOutlinedInput-input': {
            cursor: 'pointer !important',
        }
    }
}))
