import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { timeSheetApi } from 'modules/admin/api/timeSheetApi'

export const weekListAsync = createAsyncThunk('weekList', async (data, thunkAPI) => {
    try {
        let response = await timeSheetApi.getWeeksByMonthSort(data)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const timeSheetSlice = createSlice({
    name: 'timeSheet',
    initialState: {
        weekList: [],
        holidayList: [],
        leaveTypeList: [],
        externalProjectList: [],
        nonFeeProjectList: [],
        tenderProjectList: [],
        internalProjectList: [],
        selectedStaff: {},
    },
    reducers: {
        setWeekList: (state, action) => {
            state.weekList = action.payload
        },
        setHolidayList: (state, action) => {
            state.holidayList = action.payload
        },
        setLeaveList: (state, action) => {
            state.leaveTypeList = action.payload
        },
        setExternalProjectList: (state, action) => {
            state.externalProjectList = action.payload
        },
        setNonFeeProjectList: (state, action) => {
            state.nonFeeProjectList = action.payload
        },
        setTenderProjectList: (state, action) => {
            state.tenderProjectList = action.payload
        },
        setInternalProjectList: (state, action) => {
            state.internalProjectList = action.payload
        },
        setSelectedStaff: (state, action) => {
            state.selectedStaff = action.payload
        },
    },
    extraReducers: {
        [weekListAsync.fulfilled]: (state, action) => {
            state.weekList = action.payload
        },
    },
})

export default timeSheetSlice.reducer

export const { setWeekList } = timeSheetSlice.actions
export const { setHolidayList } = timeSheetSlice.actions
export const { setLeaveTypeList } = timeSheetSlice.actions

export const { setExternalProjectList } = timeSheetSlice.actions
export const { setNonFeeProjectList } = timeSheetSlice.actions
export const { setTenderProjectList } = timeSheetSlice.actions
export const { setInternalProjectList } = timeSheetSlice.actions
export const { setSelectedStaff } = timeSheetSlice.actions

// Save Selected data
export const weekList = (state) => state.timeSheet.weekList
export const holidayList = (state) => state.timeSheet.holidayList
export const leaveTypeList = (state) => state.timeSheet.leaveTypeList

// Project List for add on actions
export const externalProjectList = (state) => state.timeSheet.externalProjectList
export const nonFeeProjectList = (state) => state.timeSheet.nonFeeProjectList
export const tenderProjectList = (state) => state.timeSheet.tenderProjectList
export const internalProjectList = (state) => state.timeSheet.internalProjectList
export const selectedStaff = (state) => state.timeSheet.selectedStaff

// Get All details
export const timeSheetInfo = (state) => state.timeSheet
