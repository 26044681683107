import { createSlice } from '@reduxjs/toolkit'

const SnackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        isVisible: false,
        title: '',
        type: 'success',
    },
    reducers: {
        showSnackbar: (state, { payload }) => {
            state.isVisible = true
            state.title = payload.title
            state.type = payload.type
        },
        hideSnackbar: (state) => {
            state.isVisible = false
            state.title = ''
        },
    },
})

export const { showSnackbar, hideSnackbar } = SnackbarSlice.actions

export const snackbarState = (state) => state.snackbar

export default SnackbarSlice.reducer
