import { createSlice } from '@reduxjs/toolkit'

const pageSlice = createSlice({
    name: 'pageInfo',
    initialState: {
        title: '',
        loader: false,
        module_id: null,
        socket: {
            description: null,
            encrypted: false,
        },
    },
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload.title
        },
        resetPage: (state) => {
            state.title = ''
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setModuleId: (state, action) => {
            state.module_id = action.payload
        },
        setSocketInfo: (state, action) => {
            state.socket = action.payload
        },
        clearSocketInfo: (state) => {
            state.socket = {
                description: null,
                encrypted: false,
            }
        },
    },
})

export const { setTitle, resetPage, setLoader, setModuleId, setSocketInfo, clearSocketInfo } = pageSlice.actions

export default pageSlice.reducer

export const pageTitle = (state) => state.pageInfo.title
export const pageLoader = (state) => state.pageInfo.loader
export const getModuleId = (state) => state.pageInfo.module_id
