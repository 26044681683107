import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { clientApi } from 'modules/admin/api/clientApi'

export const getLastClientAsync = createAsyncThunk('lastClient', async (data, thunkAPI) => {
    try {
        let response = await clientApi.getLastClient()
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const clientInvoiceContactsListAsync = createAsyncThunk('invoiceContacts', async (data, thunkAPI) => {
    try {
        let response = await clientApi.getClientInvoiceContacts(data)
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        lastClient: '',
        selectedClientCode: '',
        invoiceContactsList: [],
        editedForm: false,
        projectStatus: [],
        disabledStatus: false,
    },
    reducers: {
        setSelectedClientCode: (state, action) => {
            state.selectedClientCode = action.payload
        },
        setEditedForm: (state, action) => {
            state.editedForm = action.payload
        },
        setProjectStatus: (state, action) => {
            state.projectStatus = action.payload
        },
        setDisabledStatus: (state, action) => {
            state.disabledStatus = action.payload
        },
    },
    extraReducers: {
        [getLastClientAsync.fulfilled]: (state, action) => {
            state.lastClient = action.payload
        },
        [clientInvoiceContactsListAsync.fulfilled]: (state, action) => {
            state.invoiceContactsList = action.payload
        },
    },
})

export default projectSlice.reducer

export const { setSelectedClientCode } = projectSlice.actions
export const { setEditedForm } = projectSlice.actions
export const { setProjectStatus } = projectSlice.actions
export const { setDisabledStatus } = projectSlice.actions

export const selectedClientCode = (state) => state.project.selectedClientCode
export const editedForm = (state) => state.project.editedForm
export const projectStatus = (state) => state.project.projectStatus
export const disabledStatus = (state) => state.project.disabledStatus

export const projectInfo = (state) => state.project
