import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material'
import CustomNoRowsOverlay from './CustomNoRowsOverlay'
import GridFooterRow from './GridFooterRow'
import { useTheme } from '@emotion/react'

const DataTable = ({ rows = [], rowHeight = 50, ...props }) => {
    const theme = useTheme()

    return (
        <>
            <DataGrid
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoRowsOverlay,
                    ColumnSortedDescendingIcon: ExpandMoreIcon,
                    ColumnSortedAscendingIcon: ExpandLessIcon,
                    Footer: () => <GridFooterRow stickyRowCount={props.stickyRowCount} />,
                }}
                columns={
                    props.columns &&
                    props.columns.map((column) => ({
                        ...column,
                    }))
                }
                rows={rows}
                pagination
                paginationMode="server"
                // rowsPerPageOptions={[10, 20, 30, 40, 50]}
                autoHeight={true}
                rowHeight={rowHeight}
                getRowHeight={() => (props.enableAutoRowHeight ? 'auto' : null)}
                headerHeight={50}
                //density="compact"
                disableSelectionOnClick
                disableColumnMenu
                sx={{
                    '&.MuiDataGrid-root': {
                        border: 'none',
                        '& .MuiDataGrid-main': {
                            '&>div:first-of-type': {
                                height: props?.rows?.length === 0 ? '100px!important' : 'unset',
                            },
                        },
                        '& .MuiDataGrid-row--dynamicHeight': {
                            minHeight: `${rowHeight}px !important`,
                        },
                    },
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiPaginationItem-page.Mui-selected': {
                        color: '#fff',
                    },
                    '& .MuiDataGrid-row.Mui-disabled': {
                        backgroundColor: theme.palette.grey[200],
                        position: 'fixed',
                        bottom: '-50px',
                        left: 0,
                    },
                    '& .MuiDataGrid-cell': {
                        wordBreak: 'break-word',
                    },

                    '& .MuiPaginationItem-page': {
                        color: '#000',
                    },
                    '& .MuiPaginationItem-root': {
                        marginInline: 0.9,
                    },
                    '& .MuiPaginationItem-previousNext': {
                        border: `1px solid ${theme.palette.secondary.main}`,
                        color: theme.palette.secondary.main,
                        paddingInline: 2,
                    },
                    '& .MuiPaginationItem-previousNext.Mui-disabled': {
                        color: theme.palette.grey[400],
                        border: `1px solid ${theme.palette.grey[400]}`,
                    },
                    '& .MuiDataGrid-overlay': {
                        background: 'transparent',
                    },
                    // ".MuiDataGrid-columnHeaders": {
                    // 	backgroundColor: theme.palette.primary.main,
                    // 	color: theme.palette.primary.contrastText,
                    // },
                    // '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    //     py: '7px',
                    // },
                }}
                {...props}
            />
        </>
    )
}

export default DataTable
