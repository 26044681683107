import { useEffect, useState } from 'react'
// Mui
import styled from '@emotion/styled'
import { AppBar, Box, Button, Collapse, InputAdornment, Stack, Typography, useScrollTrigger } from '@mui/material'
// Custom Icon
import { SearchIcon } from 'constants/icons'
// Redux
import { pageTitle } from 'app/slices/pageSlice'
import { useSelector } from 'react-redux'
// Icons
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
// Components
import Input from 'components/Form/OutlinedInput'
import AvatarMenu from './AvatarMenu'
// Hooks
import { useQuery } from 'hooks/useQuery'
// Router
import { useNavigate } from 'react-router-dom'
// i18
import { useTranslation } from 'react-i18next'
import { currentUser } from 'app/slices/authSlice'

const Header = ({ handleToggle, open }) => {
    const title = useSelector(pageTitle)
    const navigate = useNavigate()
    let query = useQuery().get('q')
    const [searchQuery, setSearchQuery] = useState(query || '')
    const [isOffline, setIsOffline] = useState(false)

    const [showSearch, setShowSearch] = useState(false)

    const user = useSelector(currentUser)

    useEffect(() => {
        if (user) {
            user.menuList.forEach((item) => {
                if (item.module_id === '5') {
                    setShowSearch(true)
                }
            })
        }
    }, [user])

    const { t } = useTranslation('alerts')

    // MUi Scroll event hook
    const trigger = useScrollTrigger({
        threshold: 10,
        disableHysteresis: true,
    })

    const findInSearch = (event) => {
        var keyCode = event.keyCode || event.which
        if (keyCode === 13 && event.target.value) {
            let searchKeyword = event.target.value
            setSearchQuery('')
            navigate(`/search?q=${searchKeyword}`)
        }
    }

    useEffect(() => {
        if (!navigator.onLine) {
            setIsOffline(true)
        }
    }, [])

    const [netTheme, setNetTheme] = useState('error')

    // event listeners to update the state
    window.addEventListener('online', () => {
        setNetTheme('success')
        setTimeout(() => {
            setIsOffline(false)
        }, 1500)
    })

    window.addEventListener('offline', () => {
        setNetTheme('error')
        setIsOffline(true)
    })

    return (
        <>
            <Collapse in={isOffline}>
                <StyledInfo bg={netTheme}>
                    {netTheme === 'error' ? t('text_you_are_offline') : t('text_back_to_online')}!
                </StyledInfo>
            </Collapse>
            <Root
                position="sticky"
                color={trigger ? 'primary' : 'secondary'}
                elevation={trigger ? 1 : 0}
                sx={{ backgroundColor: trigger ? '#fff' : 'transparent' }}
            >
                {/* Page title */}
                <Stack direction={'row'} alignItems="center">
                    <MenuBtn onClick={handleToggle} variant="contained" color="primary">
                        {open ? (
                            <MenuOpenIcon />
                        ) : (
                            <MenuOpenIcon
                                sx={{
                                    transform: 'rotate(180deg)',
                                }}
                            />
                        )}
                    </MenuBtn>

                    <PageTitle variant="h5" color="primary" fontWeight={'600'}>
                        {title}
                    </PageTitle>
                </Stack>
                <Stack direction={'row'} alignItems="center" spacing={2}>
                    {/* SearchBar */}
                    {showSearch && (
                        <SearchBar
                            placeholder={t('common:text_search')}
                            variant="outlined"
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            onKeyPress={(e) => findInSearch(e)}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                        />
                    )}

                    {/* Avatar Menu */}
                    <AvatarMenu />
                </Stack>
            </Root>
        </>
    )
}

export default Header

// Styles
const Root = styled(AppBar)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.3),
    position: 'sticky',
    top: 0,
    zIndex: 1200,
    flexDirection: 'row',
    marginBottom: 10,
    background: '#fff',
}))

const SearchBar = styled(Input)(({ theme }) => ({
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
        maxWidth: '300px',
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '600px',
        width: '300px',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '600px',
        width: '600px',
    },
    '& svg': {
        fontSize: 16,
        color: theme.palette.grey[400],
    },
}))

const PageTitle = styled(Typography)(({ theme }) => ({
    marginLeft: 20,
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
        marginLeft: 20,
        marginRight: 10,
    },
}))

const MenuBtn = styled(Button)(({ theme }) => ({
    // background: theme.palette.primary.main,
    // left: -10,
    borderRadius: 3,
    padding: theme.spacing(1),
    minWidth: 'unset',
    // borderTopLeftRadius: 0,
    // borderBottomLeftRadius: 0,
    // "&:hover": {
    // 	background: theme.palette.primary.dark,
    // },
}))

const StyledInfo = styled(Box)(({ theme, bg }) => ({
    width: '100%',
    paddingBlock: 3,
    backgroundColor: theme.palette[bg].main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    transition: 'all .2s ease',
}))
