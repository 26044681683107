import api from 'utils/api'

const getConsultants = (data) => {
    return api.post(`/projectPlanning/getConsultants`, data)
}

const getStaffs = (data) => {
    return api.post(`/projectPlanning/getStaffs`, data)
}

const getPositions = (data) => {
    return api.post(`/projectPlanning/getPositions`, data)
}

const getRateTypes = (data) => {
    return api.post(`/projectPlanning/getRateTypes`, data)
}

const addConsultant = (data) => {
    return api.post(`/projectPlanning/addConsultant`, data)
}

const getExpense = (data) => {
    return api.post(`/projectPlanning/task/getExpense`, data)
}

const updateExpense = (data) => {
    return api.post(`/projectPlanning/task/updateExpense`, data)
}

const getTask = (data) => {
    return api.post(`/projectPlanning/task`, data)
}

const getTaskDesc = (data) => {
    return api.post(`/projectPlanning/task/getDesc`, data)
}

const updateTaskDesc = (data) => {
    return api.post(`/projectPlanning/task/updateDesc`, data)
}

const getMonths = (data) => {
    return api.post(`/projectPlanning/task/getMonths`, data)
}

const getCalculations = (data) => {
    return api.post(`/projectPlanning/task/calculations`, data)
}

const updateTask = (data) => {
    return api.post(`/projectPlanning/task/update`, data)
}

const deleteTask = (data) => {
    return api.post(`/projectPlanning/task/delete`, data)
}

const getDeliverables = (data) => {
    return api.post(`/projectPlanning/getDeliverables`, data)
}

const addDeliverables = (data) => {
    return api.post(`/projectPlanning/addDeliverables`, data)
}

const getPhaseAndTasks = (data) => {
    return api.post(`/projectPlanning/task/getPhaseAndTasks`, data)
}

const timeSummary = (data) => {
    return api.post(`/projectPlanning/timeSummary`, data)
}

const getExpenseList = (data) => {
    return api.post(`/projectPlanning/task/expenseList`, data)
}

const getExpenseSummary = (data) => {
    return api.post(`/projectPlanning/task/expenseSummary`, data)
}

const getFullDesc = (data) => {
    return api.post(`/projectPlanning/task/fullDesc`, data)
}

const generateReport = ({ project_number, category }) => {
    return api.get(`/projectPlanning/task/report?project_number=${project_number}&category=${category}`, {
        responseType: 'blob',
    })
}

export const storeInvoiceScheduleByID = (data) => {
    return api.post(`/projects/storeInvoiceScheduleByIDonPP`, data)
}

const ProjectPlanningApi = {
    getConsultants,
    getStaffs,
    getPositions,
    getRateTypes,
    addConsultant,
    getExpense,
    updateExpense,
    getTask,
    getTaskDesc,
    updateTaskDesc,
    getMonths,
    getCalculations,
    updateTask,
    getDeliverables,
    getPhaseAndTasks,
    addDeliverables,
    timeSummary,
    getExpenseList,
    getExpenseSummary,
    getFullDesc,
    generateReport,
    deleteTask,
    storeInvoiceScheduleByID,
}

export default ProjectPlanningApi
