import { createSlice } from '@reduxjs/toolkit';

export const invoiceMaintenanceSlice = createSlice({
    name: 'invoiceMaintenance',
    initialState:{
        invoiceContacts: [],
        selectedContacts: [],
        projectInfo: {},
        projectManagers: [],
        weekCodes: [],
        weekRange: {}
    },
    reducers: {
        setInvoiceContacts: (state, action) => {
            state.invoiceContacts = action.payload; 
        },
        setSelectedContacts: (state, action) => {
            state.selectedContacts = action.payload; 
        },
        setProjectInfo: (state, action) => {
            state.projectInfo = action.payload; 
        },
        setWeekCodes: (state, action) => {
            state.weekCodes = action.payload;
        },
        setWeekRange: (state, action) => {
            state.weekRange = action.payload;
        },
        setProjectManagers: (state, action) => {
            state.projectManagers = action.payload;
        }
    },
    extraReducers: {
        
    }
});

export default invoiceMaintenanceSlice.reducer;

export const { setInvoiceContacts } = invoiceMaintenanceSlice.actions;
export const { setSelectedContacts } = invoiceMaintenanceSlice.actions;
export const { setProjectInfo } = invoiceMaintenanceSlice.actions;
export const { setProjectManagers } = invoiceMaintenanceSlice.actions;
export const { setWeekCodes } = invoiceMaintenanceSlice.actions;
export const { setWeekRange } = invoiceMaintenanceSlice.actions;

export const invoiceContacts = (state) => state.invoiceMaintenance.invoiceContacts;
export const selectedContacts = (state) => state.invoiceMaintenance.selectedContacts;
export const projectInfo = (state) => state.invoiceMaintenance.projectInfo;
export const projectManagers = (state) => state.invoiceMaintenance.projectManagers;
export const weekCodes = (state) => state.invoiceMaintenance.weekCodes;
export const weekRange = (state) => state.invoiceMaintenance.weekRange;

// Get All details
export const invoiceMaintenanceInfo = (state) => state.invoiceMaintenance;