import { useState } from 'react'
// Mui
import styled from '@emotion/styled'
import { Avatar, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
// Icons
import LogoutIcon from '@mui/icons-material/Logout'
// Azure
import { useMsal } from '@azure/msal-react'
// Redux
import { urls } from 'modules/admin/routes'
// i18next
import { useTranslation } from 'react-i18next'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { authLogoutAsync, currentUser } from '../../../../app/slices/authSlice'
import { showSnackbar } from '../../../../app/slices/snackbarSlice'
// Router
import { useLocation, useNavigate } from 'react-router-dom'

const AvatarMenu = () => {
    const dispatch = useDispatch()
    const user = useSelector(currentUser)

    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const navigate = useNavigate()
    const location = useLocation()

    const { t } = useTranslation('header')

    const { instance } = useMsal()

    // Function to logout
    const handleAuthLogout = async () => {
        dispatch(authLogoutAsync())
            .unwrap()
            .then(() => {
                instance.logout({ onRedirectNavigate: false }).then(() => {
                    navigate(urls.login, { replace: true, state: { from: location } }) // Redirect to login route
                })
            })
            .catch((e) => {
                if (e.response?.data?.errors && e.response?.data?.errors.password) {
                    dispatch(
                        showSnackbar({
                            type: 'error',
                            title: `${e.response.data?.errors.password[0]}`,
                        })
                    )
                }
            })
    }

    return (
        <>
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={t('text_open_settings')}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: 1 }}>
                        <StyledAvatar alt={user?.StaffName?.slice(0, 2)}>
                            {user?.StaffName?.match(/\b\w/g).join('').slice(0, 2)}
                        </StyledAvatar>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px', '& .MuiList-root': { minWidth: 140 } }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem disabled sx={{ paddingBottom: 0 }}>
                        <Typography textAlign="center">{t('text_profile')}</Typography>
                    </MenuItem>
                    <Divider sx={{ marginInline: 2, marginTop: 0 }} />
                    <MenuItem onClick={() => handleAuthLogout()}>
                        <LogoutIcon sx={{ marginRight: 2 }} />
                        <Typography textAlign="center">{t('text_logout')}</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </>
    )
}

export default AvatarMenu

// Styles
const StyledAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    fontSize: '1rem',
}))
