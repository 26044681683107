import api from "utils/api";

export const getStaffAndWeek = (data) => {
	return api.post(`/timeSheet`, data);
};

export const getDataEntryValues = (data) => {
	return api.post(`/timeSheet/getDataEntry`, data);
}

export const Store = (data) => {
	return api.post(`/timeSheet/store`, data, {
		"content-type": "multipart/form-data",
	});
};

export const Edit = (data) => {
	return api.post(`/timeSheet/edit`, data);
};

export const Delete = (data) => {
	return api.post(`/timeSheet/delete`, data);
};

export const timeSheetApi = {
	getStaffAndWeek,
	getDataEntryValues,
	Store,
	Edit,
	Delete,
};

export default timeSheetApi;