import { lazy } from 'react'

// lazy loading
import Loadable from '../../../components/Loadable'

const Login = Loadable(lazy(() => import('../auth/pages/Login')))
const CheckAuth = Loadable(lazy(() => import('../auth/pages/CheckAuth')))

const Project = Loadable(lazy(() => import('../pages/project/Index')))
const ClientMaintenance = Loadable(lazy(() => import('../pages/clientMaintenance/Index')))
const AddExtraWeeks = Loadable(lazy(() => import('../pages/ExtraWeeks/Index')))
const Home = Loadable(lazy(() => import('../pages/home/Index')))
const InvoiceContactMaintenance = Loadable(lazy(() => import('../pages/invoiceContactMaintenance/Index')))
const InvoiceMaintenance = Loadable(lazy(() => import('../pages/invoiceMaintenance/Index')))
const PanelMaintenance = Loadable(lazy(() => import('../pages/panelMaintenance/Index')))
const ProjectPlanning = Loadable(lazy(() => import('../pages/projectPlanning/Index')))
const ProjectSummary = Loadable(lazy(() => import('../pages/projectSummary/Index')))
const ProjectValidation = Loadable(lazy(() => import('../pages/projectValidation/Index')))
const Search = Loadable(lazy(() => import('../pages/search/Index')))
const StaffMaintenance = Loadable(lazy(() => import('../pages/staffMaintenance')))
const ProjectLibraryKeywordFields = Loadable(lazy(() => import('../pages/projectLibraryKeywordFields/Index')))
const ProjectLibraryMethods = Loadable(lazy(() => import('../pages/projectLibraryMethods/Index')))
const TimeSheet = Loadable(lazy(() => import('../pages/timeSheet/Index')))
const StaffLeaveAdjustment = Loadable(lazy(() => import('../pages/staffLeaveAdjustment/Index')))

// Reports
const CapacityReport = Loadable(lazy(() => import('../pages/reports/capacityReport/Index')))
const ExecutiveReport = Loadable(lazy(() => import('../pages/reports/executiveReport/Index')))
const LeadershipReport = Loadable(lazy(() => import('../pages/reports/leadershipReport/Index')))
const StaffReport = Loadable(lazy(() => import('../pages/reports/staffReport/Index')))
const PublicHoliday = Loadable(lazy(() => import('../pages/publicHoliday/Index')))
const StaffLeaveDetailReport = Loadable(lazy(() => import('../pages/reports/staffLeaveDetailReport/Index')))
const StaffLeaveBalanceReport = Loadable(lazy(() => import('../pages/reports/staffLeaveBalanceReport/Index')))
const StaffLeaveSummaryReport = Loadable(lazy(() => import('../pages/reports/staffLeaveSummaryReport/Index')))
const StaffEmploymentReport = Loadable(lazy(() => import('../pages/reports/staffEmploymentReport/Index')))
const WeeklyClosure = Loadable(lazy(() => import('../pages/weeklyClosure/Index')))
const MonthlyClosure = Loadable(lazy(() => import('../pages/monthlyClosure/Index')))
const ManageAccounts = Loadable(lazy(() => import('../pages/manageAccounts/Index')))
const InvoiceSearch = Loadable(lazy(() => import('../pages/invoiceSearch/Index')))
const CashFlow = Loadable(lazy(() => import('../pages/cashFlow/Index')))
const TargetIncome = Loadable(lazy(() => import('../pages/targetIncome/Index')))

//Project QA
const ProjectQA = Loadable(lazy(() => import('../pages/projectQA/feedback/qa/Index')))
const ProjectQAForm = Loadable(lazy(() => import('../pages/projectQA/feedback/qa/Form')))

//Project PM
const ProjectPM = Loadable(lazy(() => import('../pages/projectQA/feedback/pm/Index')))
const ProjectPMForm = Loadable(lazy(() => import('../pages/projectQA/feedback/pm/Form')))

// Mid Project
const MidProject = Loadable(lazy(() => import('../pages/projectQA/feedback/mid/Index')))
const MidProjectForm = Loadable(lazy(() => import('../pages/projectQA/feedback/mid/Form')))

const TenderProject = Loadable(lazy(() => import('../pages/projectQA/feedback/tender/Index')))
const TenderProjectForm = Loadable(lazy(() => import('../pages/projectQA/feedback/tender/Form')))

const ProjectQAReport = Loadable(lazy(() => import('../pages/projectQA/report/qa/Index')))
const TenderFeedbackReport = Loadable(lazy(() => import('../pages/projectQA/report/Tender/Index')))

// Staff Role
const StaffRole = Loadable(lazy(() => import('../pages/staffRole/Index')))
const Teams = Loadable(lazy(() => import('../pages/teams/Index')))

export const urls = {
    login: '/login',
    checkAuth: '/checking-authentication-status',
    Home: '/',
    search: '/search',
    searchProject: '/search-project',
    //Maintenance
    staffMaintenance: '/staffMaintenance',
    keywordFields: '/keyword-fields',
    keywordMethods: '/keyword-methods',
    panelMaintenance: '/panel-maintenance',
    clients: '/clients',
    staffRole: '/staff-role',
    teams: '/teams',
    //Project Management
    project: '/project',
    //addProject: '/project',
    projectPlanning: '/project-planning',
    projectValidation: '/project-validation',
    projectSummary: '/project-summary',
    timeSheet: '/time-sheet',
    invoiceContacts: '/invoice-contacts',
    //Administration
    weeklyClosure: '/weekly-closure',
    monthlyClosure: '/monthly-closure',
    extraWeeks: '/extra-weeks',
    addExtraWeeks: '/add-weeks',
    publicHolidays: '/public-holidays',
    managementAccounts: '/accounts',
    cashFlow: '/cash-flow',
    invoiceContactMaintenance: '/invoice-contact-maintenance',
    invoiceMaintenance: '/invoice-maintenance',
    invoiceSearch: '/invoice-search',
    staffLeaveAdjust: '/adjust-staff-leave',
    staffLeaveAdjustment: '/staff-leave-adjustment',
    targetIncome: '/recalc-target-income',
    // Reports
    staffLeaveDetail: '/reports/staff-leave',
    staffLeaveBalanceDetail: '/reports/staff-leave-balance',
    staffLeaveSummary: '/reports/staff-leave-summary',
    capacityReport: '/reports/capacity-report',
    executiveReport: '/reports/executive-report',
    leadershipReport: '/reports/leadership-report',
    staffReport: '/reports/staff-report',
    staffEmploymentReport: '/reports/staff-employment-report',

    //Project QA
    qaFeedbackList: '/project-qa/qa',
    qaFeedbackForm: '/project-qa/qa/feedback',

    pmFeedbackList: '/project-qa/pm',
    pmFeedbackForm: '/project-qa/pm/feedback',

    midProjectReviewList: '/project-qa/mid-project',
    midProjectReviewForm: '/project-qa/mid-project/review',

    tenderFeedbackList: '/project-qa/tender',
    tenderFeedbackForm: '/project-qa/tender/feedback',

    projectQAReport: '/project-qa/report/qa',
    tenderFeedbackReport: '/project-qa/report/tender',



}

// routes
const adminRoutes = [
    {
        path: urls.login,
        element: <Login />,
    },
    {
        path: urls.checkAuth,
        element: <CheckAuth />,
    },
    {
        path: urls.Home,
        element: <Home module_id={2} />,
        auth: true,
        module_id: 2,
    },
    {
        path: urls.projectPlanning + '/:projectID',
        element: <ProjectPlanning module_id={4} />,
        auth: true,
        module_id: 4,
    },
    {
        path: urls.staffMaintenance,
        element: <StaffMaintenance module_id={22} />,
        auth: true,
        module_id: 22,
    },
    {
        path: urls.project,
        element: <Project module_id={3} />,
        auth: true,
        module_id: 3,
    },
    {
        path: urls.project + '/:projectNumber',
        element: <Project module_id={3} />,
        auth: true,
        module_id: 3,
    },
    {
        path: urls.addExtraWeeks,
        element: <AddExtraWeeks module_id={16} />,
        auth: true,
        module_id: 16,
    },
    {
        path: urls.clients,
        element: <ClientMaintenance module_id={12} />,
        auth: true,
        module_id: 12,
    },
    {
        path: urls.invoiceContacts,
        element: <InvoiceContactMaintenance module_id={13} />,
        auth: true,
        module_id: 13,
    },
    {
        path: urls.invoiceMaintenance,
        element: <InvoiceMaintenance module_id={20} />,
        auth: true,
        module_id: 20,
    },
    {
        path: urls.projectSummary,
        element: <ProjectSummary module_id={11} />,
        auth: true,
        module_id: 11,
    },
    {
        path: urls.projectSummary + '/:projectCode',
        element: <ProjectSummary module_id={11} />,
        auth: true,
        module_id: 11,
    },
    {
        path: urls.staffLeaveSummary,
        element: <StaffLeaveSummaryReport module_id={25} />,
        auth: true,
        module_id: 25,
    },
    {
        path: urls.keywordFields,
        element: <ProjectLibraryKeywordFields module_id={6} />,
        auth: true,
        module_id: 6,
    },
    {
        path: urls.keywordMethods,
        element: <ProjectLibraryMethods module_id={7} />,
        auth: true,
        module_id: 7,
    },
    {
        path: urls.panelMaintenance,
        element: <PanelMaintenance module_id={8} />,
        auth: true,
        module_id: 8,
    },
    {
        path: urls.staffRole,
        element: <StaffRole module_id={56} />,
        auth: true,
        module_id: 56,
    },
    {
        path: urls.teams,
        element: <Teams module_id={57} />,
        auth: true,
        module_id: 57,
    },
    {
        path: urls.projectValidation,
        element: <ProjectValidation module_id={10} />,
        auth: true,
        module_id: 10,
    },
    {
        path: urls.publicHolidays,
        element: <PublicHoliday module_id={17} />,
        auth: true,
        module_id: 17,
    },
    {
        path: urls.search,
        element: <Search module_id={5} />,
        auth: true,
        module_id: 5,
    },
    {
        path: urls.searchProject,
        element: <Search module_id={55} />,
        auth: true,
        module_id: 55,
    },
    {
        path: urls.timeSheet,
        element: <TimeSheet module_id={9} />,
        auth: true,
        module_id: 9,
    },
    {
        path: urls.staffLeaveAdjustment,
        element: <StaffLeaveAdjustment module_id={23} />,
        auth: true,
        module_id: 23,
    },
    //Reports
    {
        path: urls.capacityReport,
        element: <CapacityReport module_id={26} />,
        auth: true,
        module_id: 26,
    },
    {
        path: urls.executiveReport,
        element: <ExecutiveReport module_id={29} />,
        auth: true,
        module_id: 29,
    },
    {
        path: urls.leadershipReport,
        element: <LeadershipReport module_id={33} />,
        auth: true,
        module_id: 33,
    },
    {
        path: urls.staffReport,
        element: <StaffReport module_id={37} />,
        auth: true,
        module_id: 37,
    },
    {
        path: urls.staffLeaveDetail,
        element: <StaffLeaveDetailReport module_id={24} />,
        auth: true,
        module_id: 24,
    },
    {
        path: urls.staffLeaveBalanceDetail,
        element: <StaffLeaveBalanceReport module_id={23} />,
        auth: true,
        module_id: 23,
    },
    {
        path: urls.staffEmploymentReport,
        element: <StaffEmploymentReport module_id={41} />,
        auth: true,
        module_id: 41,
    },
    {
        path: urls.weeklyClosure,
        element: <WeeklyClosure module_id={14} />,
        auth: true,
        module_id: 14,
    },
    {
        path: urls.monthlyClosure,
        element: <MonthlyClosure module_id={15} />,
        auth: true,
        module_id: 15,
    },
    {
        path: urls.managementAccounts,
        element: <ManageAccounts module_id={18} />,
        auth: true,
        module_id: 18,
    },
    {
        path: urls.invoiceSearch,
        element: <InvoiceSearch module_id={21} />,
        auth: true,
        module_id: 21,
    },
    {
        path: urls.cashFlow,
        element: <CashFlow module_id={19} />,
        auth: true,
        module_id: 19,
    },
    {
        path: urls.targetIncome,
        element: <TargetIncome module_id={47} />,
        auth: true,
        module_id: 47,
    },

    // Project QA
    {
        path: urls.qaFeedbackList,
        element: <ProjectQA module_id={49} />,
        auth: true,
        module_id: 49,
    },
    {
        path: urls.qaFeedbackForm,
        element: <ProjectQAForm module_id={49} />,
        auth: true,
        module_id: 49,
    },
    {
        path: urls.pmFeedbackList,
        element: <ProjectPM module_id={50} />,
        auth: true,
        module_id: 50,
    },
    {
        path: urls.pmFeedbackForm,
        element: <ProjectPMForm module_id={50} />,
        auth: true,
        module_id: 50,
    },
    {
        path: urls.tenderFeedbackList,
        element: <TenderProject module_id={51} />,
        auth: true,
        module_id: 50,
    },
    {
        path: urls.tenderFeedbackForm,
        element: <TenderProjectForm module_id={51} />,
        auth: true,
        module_id: 51,
    },
    {
        path: urls.midProjectReviewList,
        element: <MidProject module_id={52} />,
        auth: true,
        module_id: 52,
    },
    {
        path: urls.midProjectReviewForm,
        element: <MidProjectForm module_id={52} />,
        auth: true,
        module_id: 52,
    },

    //Project QA Report
    {
        path: urls.projectQAReport,
        element: <ProjectQAReport module_id={53} />,
        auth: true,
        module_id: 53,
    },
    {
        path: urls.tenderFeedbackReport,
        element: <TenderFeedbackReport module_id={54} />,
        auth: true,
        module_id: 54,
    }
]

export default adminRoutes
