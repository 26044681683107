import { createSlice } from '@reduxjs/toolkit'

const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        blockNavigation: false,
        isAlertOpen: false,
        msg: '',
        title: '',
        alertType: 'primary',
        actions: {
            cancel: { visible: true },
        },
        isSocketAlert: false,
    },
    reducers: {
        setFormEdited: (state, action) => {
            state.isFormEdited = action.payload
        },
        showAlert: (state, action) => {
            state.isAlertOpen = true
            state.msg = action?.payload?.msg || 'You have unsaved changes, Are you sure want to continue!'
            state.title = action?.payload?.title || 'Alert!'
            state.alertType = action?.payload?.alertType || 'primary'
            state.actions = action?.payload?.actions
            state.isSocketAlert = action.payload?.isSocketAlert || false
        },
        hideAlert: (state) => {
            state.isAlertOpen = false
        },
        setNavigationBlocker: (state, action) => {
            state.blockNavigation = action.payload
        },
        resetAlertState: (state) => {
            state.isAlertOpen = false
            state.msg = ''
            state.title = ''
            state.alertType = 'primary'
            state.actions = {
                cancel: { visible: true },
            }
            state.isSocketAlert = false
        },
    },
})

export const { setFormEdited, showAlert, hideAlert, resetAlertState, setNavigationBlocker } = alertSlice.actions

export const alertState = (state) => state.alert

export default alertSlice.reducer
