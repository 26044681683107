import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { pageLoader } from 'app/slices/pageSlice'

const PageLoader = (props) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
            open={true}
        >
            <CircularProgress color="primary" />
        </Backdrop>
    )
}

export default PageLoader

export const ReduxPageLoader = (props) => {
    const loader = useSelector(pageLoader)

    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.modal + 1,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
            open={loader}
        >
            <CircularProgress color="primary" />
        </Backdrop>
    )
}
