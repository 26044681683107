import React, { memo, useCallback, useEffect, useState } from 'react'
// Images
import AppImage from 'constants/images'
// Mui
import {
    Collapse,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
// Custom Styles
import styled from '@emotion/styled'
// Icons
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Router
import { urls } from 'modules/admin/routes'
import { Link, useLocation } from 'react-router-dom'
// Icons
import { AppIcon } from 'constants/icons'
// Hook
import useResponsive from 'hooks/useResponsive'
// redux
import { currentUser } from 'app/slices/authSlice'
import { useSelector } from 'react-redux'
// Config
import { SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH } from 'constants/config'
// i18
import { useTranslation } from 'react-i18next'
const SidebarListItem = memo(({ open, item, handleOpen, isOpen, handleToggle, setIsOpen }) => {
    const location = useLocation()
    const isSubMenu = Boolean(item.sub_menu?.length > 0)
    const isVisible = Boolean(isOpen === item.title)
    const currentURL = location.pathname.split('/').slice(1, 3)

    // Flag to set active
    let isActive = false
    if (item.sub_menu) {
        // To find if any of the submenu is active
        item.sub_menu.forEach((element) => {
            const elementURL = element.url.split('/').slice(1, 3)
            if (element.url === location.pathname || JSON.stringify(currentURL) === JSON.stringify(elementURL)) {
                isActive = true
            }
        })
    } else if (item.url === location.pathname) {
        // If do not have submenu, then check parent path is active
        isActive = true
    }

    // Custom hook to `trigger` on screen width is above `md`
    const isMd = useResponsive('up', 'md')

    // handler to toggle sidebar open state
    const handleClick = () => {
        !isMd && handleToggle()
    }

    useEffect(() => {
        isActive && setIsOpen(item.title)
    }, [isActive, item.title, open, setIsOpen])

    // Change color according to `active` state
    let color = isActive ? 'light' : 'primary'

    return (
        <React.Fragment key={item.ID}>
            <StyledListItem selected={isActive} disablePadding>
                <Tooltip title={open ? '' : item.title} arrow placement="right">
                    <ListItemButton
                        component={open && isSubMenu ? ListItemButton : Link}
                        to={open && isSubMenu ? '' : item.url ? item.url : ''}
                        onClick={() => (open && isSubMenu ? handleOpen(item.title) : handleClick())}
                        state={{ from: location }}
                    >
                        {/* ---------------- icon -------------------- */}
                        {item.icon && (
                            <ListItemIcon>
                                <AppIcon icon={item.icon} color={color} fontSize={open ? 'small' : 'medium'} />
                            </ListItemIcon>
                        )}
                        {/* ---------------- icon -------------------- */}

                        {/* ---------------- title -------------------- */}
                        <ListItemText secondary={item.title} />
                        {/* ---------------- title -------------------- */}

                        {/* ---------------- arrow -------------------- */}
                        {open &&
                            isSubMenu &&
                            (isVisible ? <ExpandLessIcon color={color} /> : <ExpandMoreIcon color={color} />)}
                        {/* ---------------- arrow -------------------- */}
                    </ListItemButton>
                </Tooltip>
            </StyledListItem>
            {/* -------------------------- Sub menu ------------------------ */}
            {open && isSubMenu && (
                <Collapse in={isVisible} timeout={'auto'}>
                    <List component={'div'} disablePadding>
                        {item?.sub_menu?.map((menu, i) => {
                            const menuSplit = menu.url.split('/')
                            let menuURL = menu.url.split('/').slice(1, 3)
                            if(menuSplit.length > 3){
                                menuURL = menu.url.split('/').slice(1, 4)
                            }
                            return (
                                <MenuItem
                                    key={menu.ID}
                                    component={Link}
                                    to={menu.url}
                                    onClick={menu.url === location.pathname ? undefined : handleClick}
                                    selected={menu.url === location.pathname || JSON.stringify(currentURL) === JSON.stringify(menuURL)}
                                    state={{ from: location }}
                                    disabled={menu.url === location.pathname}
                                >
                                    {menu.title}
                                </MenuItem>
                            )
                        })}
                    </List>
                </Collapse>
            )}
        </React.Fragment>
    )
})

const SidebarListWrapper = memo(({ open, handleToggle, user }) => {
    const [isOpen, setIsOpen] = useState('')

    const handleOpen = useCallback((title) => {
        setIsOpen((t) => (t === title ? '' : title))
    }, [])

    return (
        <>
            <StyledListWrapper open={open}>
                {user?.menuList?.map((menu, i) => {
                    return (
                        <SidebarListItem
                            key={menu.ID}
                            open={open}
                            isOpen={isOpen}
                            item={menu}
                            handleOpen={handleOpen}
                            handleToggle={handleToggle}
                            setIsOpen={setIsOpen}
                        />
                    )
                })}
            </StyledListWrapper>
        </>
    )
})

const Sidebar = ({ open, handleToggle }) => {
    // State to sidebar position
    const [isPermanent, setPermanent] = useState(true)

    // Getting user detail
    const user = useSelector(currentUser)

    // Responsive check for above `md`
    const isMd = useResponsive('up', 'md')

    const { t } = useTranslation('common')

    // Function to trigger when window width is changing
    function handleWindowWidthChange() {
        if (!isMd && isPermanent) {
            setPermanent(false)
        } else if (isMd && !isPermanent) {
            setPermanent(true)
        }
    }

    // Effect to trigger on resizing window
    useEffect(function () {
        window.addEventListener('resize', handleWindowWidthChange)
        handleWindowWidthChange()
        return function cleanup() {
            window.removeEventListener('resize', handleWindowWidthChange)
        }
    })

    return (
        <>
            <Root
                variant={isPermanent ? 'permanent' : 'temporary'}
                open={open}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={handleToggle}
            >
                <Link to={urls.Home}>
                    <LogoImg open={open}>
                        <img className="image" src={AppImage.Logo} alt="" />
                    </LogoImg>
                </Link>
                <ProfileContainer
                    direction={'column'}
                    spacing={open ? 1 : 0}
                    alignItems={open ? 'flex-start' : 'center'}
                    justifyContent={open ? 'flex-start' : 'center'}
                >
                    <Typography variant="body2" gutterBottom={false} lineHeight={1}>
                        {t('text_welcome')}
                    </Typography>
                    <Typography variant="body2" fontWeight={'600'} textTransform="uppercase">
                        {user?.StaffName?.split(' ')[0]?.slice(0, 10)}
                    </Typography>
                </ProfileContainer>
                {/* Sidebar List component */}
                {user?.menuList && <SidebarListWrapper open={open} handleToggle={handleToggle} user={user} />}
            </Root>
        </>
    )
}

export default Sidebar

const MAX_WIDTH = `${SIDEBAR_MAX_WIDTH}px`
const MIN_WIDTH = `${SIDEBAR_MIN_WIDTH}px`

const Root = styled(Drawer)(({ theme, open }) => ({
    // background: theme.palette.common.white,
    '& .MuiPaper-root': {
        position: 'fixed',
        width: open ? MAX_WIDTH : MIN_WIDTH,
        minHeight: '100vh',
        // width: 'inherit',
        borderRight: 'none',
        overflowY: 'auto',
        // position: 'unset',
        zIndex: 1200,
        [theme.breakpoints.up('md')]: {
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
            }),
        },
    },
}))

const LogoImg = styled('div')(({ theme, open }) => ({
    padding: 8,
    marginInline: 'auto',
    '& .image': {
        width: '100%',
        height: '100%',
        maxWidth: '68px',
        margin: 'auto',
    },
}))

const ProfileContainer = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    paddingBlock: theme.spacing(2),
    width: '100%',
    paddingInline: theme.spacing(2),
    color: theme.palette.common.white,
    // boxShadow: 'inset 5px 4px 4px -2px rgb(0 0 0 / 20%)',
    '& .MuiAvatar-root': {
        marginBlock: theme.spacing(1),
        '& img': {
            width: 45,
            height: 45,
            objectFit: 'cover',
            borderRadius: '50%',
        },
    },
}))

const StyledListWrapper = styled(List)(({ theme, open }) => ({
    paddingBlock: 0,
    '& .MuiListItem-root': {
        flexDirection: open ? 'row' : 'column',
    },
    '& .MuiListItemIcon-root': {
        minWidth: 38,
        justifyContent: open ? 'flex-start' : 'center',
    },
    '& .MuiListItemText-root': {
        display: open ? 'block' : 'none',
        whiteSpace: 'nowrap',
    },
    '& .MuiListItemButton-root': {
        paddingInline: theme.spacing(2),
    },
    '& .MuiList-root': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiMenuItem-root': {
            paddingLeft: theme.spacing(2),
            paddingBlock: theme.spacing(1.3),
            color: theme.palette.primary.main,
            '&.Mui-selected': {
                fontWeight: '500',
            },
        },
    },
}))

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    width: '100%',
    flex: 1,
    borderRadius: 0,
    '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
            color: theme.palette.primary.main,
            fontWeight: '500',
        },
    },

    '& .MuiListItemButton-root': {
        width: '100%',
        justifyContent: 'center',
        minHeight: 65,
        paddingInline: theme.spacing(3),
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light}`,
            '& svg': {
                color: `${theme.palette.secondary.main}!important`,
            },
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    },
    '&.Mui-selected .MuiListItemButton-root': {
        backgroundColor: theme.palette.primary.main,
        '& svg': {
            color: theme.palette.common.white,
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-root': {
                color: theme.palette.common.white,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                color: theme.palette.common.white,
            },
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    color: theme.palette.secondary.main,
                },
            },
        },
    },
}))
