import Logo from '../assets/Img/logo.png'
import Avatar from '../assets/Img/profile.svg'
import LoginBg from '../assets/Img/login-bg.png'
import WorkSVG from '../assets/Img/work.svg'
import ProjectSVG from '../assets/Img/project.svg'
import HourSVG from '../assets/Img/hours.svg'

const AppImage = {
    Logo,
    Avatar,
    LoginBg,
    WorkSVG,
    ProjectSVG,
    HourSVG,
}

export default AppImage
