import { CircularProgress, LinearProgress, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Suspense } from 'react'

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    )

export default Loadable

const Loader = () => (
    <LoaderWrapper>
        <LinearProgress color="primary" />
    </LoaderWrapper>
)

const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%',
})

export const TableCellLoader = ({ colSpan = 1 }) => {
    return (
        <TableRow>
            <TableCell align={`center`} colSpan={colSpan}>
                <CircularProgress color="primary" />
            </TableCell>
        </TableRow>
    )
}
