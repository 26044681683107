import api from 'utils/api'

export const orgTypeList = (data) => {
    return api.post('/common/getOrgTypeList', data)
}

export const govTypeList = (data) => {
    return api.post('/common/getGovTypeList', data)
}

export const countryList = (data) => {
    return api.post('/common/getCountryList', data)
}

export const stateList = (data) => {
    return api.post('/common/getStateList', data)
}

export const userTypeList = (data) => {
    return api.post('/common/getUserTypeList', data)
}

export const weekList = (data) => {
    return api.post('/common/getWeekList', data)
}

export const roleStaffList = (data) => {
    return api.get('/common/roleStaffList', data)
}

export const staffTypeList = (data) => {
    return api.post('/common/getStaffTypeList', data)
}

export const roleList = (data) => {
    return api.post('/common/getRoleList', data)
}

export const portfolioList = (data) => {
    return api.post('/common/getPortfolioList', data)
}

export const teamList = (data) => {
    return api.post('/common/getTeamList', data)
}

export const currentWeekValues = (data) => {
    return api.post('/common/getCurrentWeekValues', data)
}

export const bandList = (data) => {
    return api.post('/common/getBandList', data)
}

export const projectClassificationList = (data) => {
    return api.post('/common/getProjectClassification', data)
}

export const projectStatusList = (data) => {
    return api.post('/common/getProjectStatus', data)
}

//get all classification status
export const projectClassificationStatusList = (data) => {
    return api.post('/common/getAllProjClassificationStatus', data)
}

export const projectTypeList = (data) => {
    return api.post('/common/getProjectType', data)
}

export const tenderTypeList = (data) => {
    return api.post('/common/getTenderType', data)
}

export const projectManagerList = (data) => {
    return api.post('/common/getProjectManager', data)
}

export const projectDirectorList = (data) => {
    return api.post('/common/getPrincipleConsultOrDirector', data)
}

export const getStaffRoleRateLatest = (data) => {
    return api.post('/common/getStaffRoleRateLatest', data)
}

//Add Project
export const getProjectMasters = (data) => {
    return api.post('/common/getProjectMasters', data);
}

export const getTeamStaffs = (data) => {
    return api.post('/common/getTeamStaffs', data);
}

export const commonApi = {
    orgTypeList,
    govTypeList,
    countryList,
    stateList,
    userTypeList,
    weekList,
    roleStaffList,
    portfolioList,
    roleList,
    staffTypeList,
    teamList,
    currentWeekValues,
    bandList,
    projectClassificationList,
    projectClassificationStatusList,
    projectStatusList,
    projectTypeList,
    tenderTypeList,
    projectManagerList,
    projectDirectorList,
    getStaffRoleRateLatest,
    getProjectMasters,
    getTeamStaffs
}
