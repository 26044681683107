// Mui
import { CssBaseline } from '@mui/material'
import ThemeWrapper from './theme'

// Translation
import i18n from 'constants/config-i18n'
import { I18nextProvider } from 'react-i18next'

// Router
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './router'
// Azure
// import { WithAzure } from 'components/Azure'

const App = () => {
    return (
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                {/* Mui Theme wrapper */}
                <ThemeWrapper>
                    <CssBaseline />
                    <AppRoutes />
                </ThemeWrapper>
            </I18nextProvider>
        </BrowserRouter>
    )
}

export default App
